import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/Layout"
import * as styles from "../feature/noticeDetails/assets/css/noticeDetails.module.scss"

const NoticeDetails = ({ data }) => {
  return (
    <Layout>
      <h3>【{data.allMicrocmsNotice.edges[0].node.title}】</h3>
      {data.allMicrocmsNotice.edges[0].node.image && (
        <p>
          <img
            src={data.allMicrocmsNotice.edges[0].node.image.url}
            alt="画像の取得に失敗しました。"
          ></img>
        </p>
      )}
      <p className={styles.content}>
        {data.allMicrocmsNotice.edges[0].node.content}
      </p>
    </Layout>
  )
}

export default NoticeDetails

export const query = graphql`
  query ($slug: String!) {
    allMicrocmsNotice(filter: { id: { eq: $slug } }) {
      edges {
        node {
          title
          content
          image {
            url
          }
        }
      }
    }
  }
`
